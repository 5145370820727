<template>
  <div id="followup" class="max-w-lg mx-auto bg-white p-4">
    <div class="max-w-lg mx-auto rounded-t bg-white p-4">
      <img src="/text_fix_450px.png" class="w-full" alt="Mr Appliance Logo">

      <h1 class="text-2xl text-center max-w-md mx-auto pt-4">Last Step</h1>
    </div>

    <h3 class="text-lg m-4">
      <span class="font-bold text-xl pr-4">1)</span> Click the “Add Contact” button below, and for iPhone, scroll down and tap “Create New Contact”. On Android, tap the “+” button in the top-right of your screen. 
    </h3>

    <h3 class="text-lg m-4">
      <span class="font-bold text-xl pr-4">2)</span>  Create a new Text that says “Fix” and send it to the new contact for Mr. Appliance in your phone. 
    </h3>

    <h3 class="text-lg m-4">
      <span class="font-bold text-xl pr-4">3)</span> Show the reply to your technician for your coupon
    </h3>

     <a class="download-contact-button cursor-pointer block w-3/4 px-4 py-2 text-xl text-center rounded bg-orange-500 text-white hover:bg-orange-600 mx-auto" 
      :href="downloadUrl" @click="tapAddContact" target="_blank">
      Add Contact
    </a>

    <div class="mb-4" v-if="showSecondPart">
      <img src="/hey_siri_text_MRA.png" class="w-full pt-4 border-b-8 border-solid mx-auto" style="border-color: #0A2D44; width: 90%;" alt="Mr Appliance Logo">

      <ul class="list-disc list-inside text-left pl-4 pr-4 pt-4 text-lg">
        <li>Same Day / Next Day service</li>
        <li>Just text "<b>Fix</b>" to the Mr. Appliance contact in your phone.</li>
        <li>“Hey Siri (Hey Google), send a text message to Mr. Appliance of Orlando.  Fix”</li>
        <li>Or call anytime to speak with a human.</li>
        <li>Repairs with parts warrantied for 1 year. 6 months for Samsung.</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewCustomerSubmitted',
  data () {
    return {
      showSecondPart: false,
    }
  },
  components: {
  },
  methods: {
    tapAddContact () {
      this.showSecondPart = true
    },
  },
  computed: {
    downloadUrl () {
      return `https://mraorlando.com/clubcontact/${this.$route.query.source ? this.$route.query.source : ''}`
    }
  }
}
</script>

<style>
</style>
