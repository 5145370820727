<template>
  <div id="pm-request-form" class="max-w-lg mx-auto">
    <div class="max-w-lg mx-auto rounded bg-white p-4">
      <img src="/mra_logo_350px.png" class="mx-auto" alt="Mr Appliance Logo" width="350px">

      <h1 class="text-2xl text-center max-w-md mx-auto pt-2">Mr. Appliance of Orlando Property Manager Work Order Request</h1>
    </div>

    <div class="pm-contact-methods bg-white p-4">
      <div class="contact-header mx-auto text-center text-lg">
        Dedicated Property Manager Contact Methods
      </div>
      <div class="contact-info flex mx-auto max-w-lg flex-auto">
        <div class="phone-number pt-2 flex-grow text-center">
          <a href="tel:4077207979">407-720-7979</a>
        </div>
        <div class="email pt-2 flex-grow text-center">
          <a href="mailto:pm@mraorlando.com">pm@mraorlando.com</a>
        </div>
      </div>

    </div>

     <FormulateForm
      @submit="submitForm"
      ref="form"
      class="max-w-lg mx-auto"
      v-model="pmValues"
      :schema="pmSchema"
    />
  </div>
</template>

<script>

const pmFormSchema = [
  {
    component: 'div',
    class: 'p-4 bg-gray-100 mb-4',
    children: [
      {
        name: 'pm_authorization_code',
        label: 'Your Authorization Phrase',
        type: 'text',
        help: 'The authorization phrase you set when you submitted your PM packet. We use this to make sure this request is really from your company!',
        validation: 'required',
        "validation-name": 'Authorization Phrase'
      },
      {
        component: 'div',
        class: '',
        children: "Don't have an authorization phrase? You set up your authorization phrase when you submit your PM packet. Call our Property Management phone line to learn more."
      },
    ],
  },
  {
    component: 'div',
    class: 'p-4 bg-white rounded mb-4',
    children: [
      {
        component: 'h3',
        class: 'text-lg font-bold mb-4',
        children: 'The Appliance Issues'
      },
      {
        component: 'div', 
        class: 'text-medium mb-4',
        children: 'What are the appliance issues your property is having? Fill out the form below for your first appliance and click "Add Another Appliance" as many times as needed for additional.'
      },
      {
        type: 'group',
        name: 'appliances',
        addLabel: 'Add Another Appliance',
        repeatable: true,
        children: [
          {
            name: 'appliance_type',
            label: 'Appliance Type',
            type: 'select',
            options: [
              'Refrigerator',
              'Standalone Freezer',
              'Washing Machine',
              'Dryer',
              'Dishwasher',
              'Oven',
              'Range',
              'Microwave',
              'Wine Cooler',
              'Standalone Ice Machine',
              'Garbage Disposal',
              'Dryer Vent Cleaning',
              'Other',
            ],
            validation: 'required',
            "validation-name": 'Appliance Type'
          },
          {
            name: 'appliance_brand',
            label: 'Appliance Brand',
            type: 'text',
          },
          {
            name: 'appliance_problem',
            label: 'Appliance Problem',
            type: 'textarea',
            "help-position": 'before',
            help: 'What is going on with the appliance?'
          },
        ]
      }
    ]
  },
  {
    component: 'div',
    class: 'p-4 bg-white rounded mb-4',
    children: [
      {
        component: 'h3',
        class: 'text-xl font-bold mb-4',
        children: 'Property Information'
      },
      {
        name: 'property_address_1',
        label: 'Property Address 1',
        type: 'text',
        validation: 'required',
        "validation-name": 'Address 1'
      },
      {
        name: 'property_address_2',
        label: 'Property Address 2',
        type: 'text',
      },
      {
        name: 'property_zip_code',
        label: 'Property Zip Code',
        class: 'max-w-8',
        type: 'text',
        validation: 'required|number|max:5,length|min:5,length',
        "validation-name": 'Zip code',
      },
      {
        name: 'property_address_notes',
        label: 'Property Address Notes',
        type: 'textarea',
        "help-position": 'before',
        help: 'Gate Code? Same unit number on both sides of a building? Long driveway? Gate house? High rise w/ parking?'
      },
    ]
  },
  {
    component: 'div',
    class: 'p-4 bg-white rounded mb-4',
    children: [
      {
        component: 'h3',
        class: 'text-lg font-bold mb-4',
        children: 'Tenant Information'
      },
      {
        type: 'group',
        name: 'tenants',
        repeatable: true,
        addLabel: 'Add Another Tenant',
        children: [
          {
            name: 'tenant_name',
            label: 'Tenant Name',
            type: 'text',
            validation: 'required',
            "validation-name": 'Tenant Name'
          },
          {
            name: 'tenant_phone',
            label: 'Tenant Phone',
            type: 'text',
            validation: 'required|phoneNumber',
            "validation-name": 'Tenant Phone'
          },
          {
            name: 'tenant_note',
            label: 'Tenant Note',
            type: 'textarea',
            "help-position": 'before',
            help: 'Is there anything we should know about this tenant?'
          },
        ]
      },
      {
        component: 'div',
        class: 'p-2',
        children: '',
      },
      {
        name: 'tenant_availability_notes',
        label: 'Tenant Availability Notes (if known)',
        type: 'textarea',
        "help-position": 'before',
        help: 'Has the tenant already communicated availability to you? If not, we will figure it out.'
      },
    ]
  },
  {
    component: 'div',
    class: 'p-4 bg-white rounded mb-4',
    children: [
      {
        component: 'h3',
        class: 'text-lg font-bold mb-4',
        children: 'Property Management Company Information'
      },
      {
        name: 'pm_company_name',
        label: 'PM Company Name',
        type: 'text',
        validation: 'required',
        "validation-name": 'Company Name',
      },
      {
        name: 'pm_requester_name',
        label: 'PM Requester Name',
        type: 'text',
        help: 'Your name requesting service as the property manager',
        "help-position": 'before',
        validation: 'required',
        "validation-messages": {
          required: 'The name of the person from the property management company is required.'
        },
        "validation-name": 'Requester Name'
      },
      {
        name: 'pm_contact_name',
        label: 'PM Preferred Contact Name',
        type: 'text',
        "help-position": 'before',
        help: 'Preferred contact for property manager if different from your account.',
      },
      {
        name: 'pm_contact_phone',
        label: 'PM Preferred Contact Phone Number',
        type: 'text',
        "help-position": 'before',
        help: 'Preferred phone number for property manager if different from your account.',
        validation: 'optional|phoneNumber',
        "validation-name": 'Phone number',
      },
      {
        name: 'pm_contact_special_instructions',
        label: 'PM Contact Special Instructions',
        type: 'text',
        "help-position": 'before',
        help: 'Should we contact you differently than normal for this job?',
      },
    ]
  },
  {
    component: 'div',
    class: 'p-4 bg-white rounded mb-4',
    children: [
      {
        component: 'h3',
        class: 'font-bold text-lg mb-4',
        children: "Mr. Appliance is about to jump into action!",
      },
      {
        name: 'other_notes',
        label: 'Other Notes',
        type: 'textarea',
        "help-position": 'before',
        help: 'Is there anything else we should know?'
      },
      {
        component: 'div',
        class: 'p-4',
        children: [
          {
            component: 'h4',
            class: 'text-lg font-bold',
            children: 'What happens next?',
          },
          {
            component: 'ul',
            class: 'list-disc text-left pl-2 pr-2 pt-2',
            children: [
              {
                component: 'li',
                children: 'Our office will be notified of your request via notifications on their computers within seconds.',
              },
              {
                component: 'li',
                children: 'We confirm the authenticity of your request with your authorization code.',
              },
              {
                component: 'li',
                children: 'We call your tenant to schedule service within the next few minutes.',
              },
              {
                component: 'li',
                children: 'We look up your communication preferences and set everything up just the way you like it!'
              }
            ]
          }
        ]
      },
      {
        component: 'div',
        class: '',
        children: [
          {
            "type": "submit",
            "label": "Submit Work Order",
            class: 'w-full',
          }
        ]
      }
    ]
  },
]

import axios from 'axios'


export default {
  name: 'PmRequestForm',
  data () {
    return {
      pmSchema: pmFormSchema,
      pmValues: {},
      values: {},
      submissionStatus: 'new',
    }
  },
  components: {
  },
  methods: {
    submitForm (data) {
      console.log('here', data)
      if (this.submissionStatus === 'new') {
        this.submissionStatus = 'in-progress'
        const baseUrl = 'https://productivity-tools.webbintegrated.com'
        // const baseUrl = 'https://0b443d6ea0c9.ngrok.io'
        axios.post(`${baseUrl}/webhooks/mra_forms`, {
          form_name: 'property-manager-job-request',
          data: {
            ...this.pmValues
          }
        })
        .then(resp => {
          console.log('response', resp)
          this.submissionStatus = 'sent'
          this.$router.push('/pm/request/submitted')
        }, resp => {
          console.log('response', resp)
          this.submissionStatus = 'failed'
        })
      }

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
