import Vue from 'vue'
import PmRequestForm from './PmRequestForm.vue'
import PmRequestFollowup from './PmRequestFollowup.vue'
import NewCustomerExperienceStart from './NewCustomerExperienceStart.vue'
import NewCustomerExperience from './NewCustomerExperience.vue'
import NewCustomerExperienceSubmitted from './NewCustomerExperienceSubmitted.vue'
import JustFixItPage from './JustFixItPage.vue'

import './assets/tailwind.css'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

import parsePhoneNumber from 'libphonenumber-js/min'

Vue.config.productionTip = false

import VueFormulate from '@braid/vue-formulate'
Vue.use(VueFormulate, {
    classes: {
        outer: (context) => {
            if (context.type === 'checkbox') {
                return (context.isGrouped ? "mb-2" : "mb-8") + ' leading-loose text-left'
            }
            return (context.isGrouped ? "mb-2 leading-loose" : "mb-8") + ' text-left'
        },
        // groupRepeatable: 'bg-gray-200',
        // groupRepeatableRemove: 'px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600',
        label: () => {
            // console.log(context, context.isGrouped)
            return 'font-semibold text-lg flex-grow'
        },
        help: 'text-xs mb-1 text-gray-600',
        error: 'text-red-700 text-xs mb-1',



        wrapper: ({ classification }) => {
            switch (classification) {
                case "box":
                    return "flex";
                default:
                    return "";
            }
        },
        element: (context) => {
            // console.log('element', context)
            switch (context.classification) {
                case "group":
                    return "mt-2 pl-2"
                case "select":
                    return !context.hasValue ? "text-gray-500 font-light" : ""
                case "box":
                    if (context.type === 'checkbox') {
                        // center checkbox on multi-line labels 
                        return 'flex items-center'
                    } else {
                        return ""
                    }
                default:
                    return ""
            }
        },
        input: ({ classification, label }) => {
            switch (classification) {
                case "button":
                    if (label && label.includes('Add')) {
                        return 'cursor-pointer px-4 py-2 rounded bg-blue-400 text-white hover:bg-blue-500'
                    } else {
                        return 'cursor-pointer px-4 py-2 rounded bg-orange-500 text-white hover:bg-orange-600 w-full text-xl font-bold'
                    }
                case "group":
                    return "here"
                case "box":
                    return "sr-only"
                default:
                    return 'border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-500 outline-none border-box w-full mb-1 max-w-md'
            }
        },
        decorator: ({ hasValue }) => {
            let base = "border rounded border-black inline-block w-4 h-4 mr-2"
            if (hasValue) {
                base += " bg-orange-500"
            }
            return base;
        },
    },
    rules: {
        phoneNumber({ value }) {
            if (value.length === 0) {
                return true
            } else {
                const pn = parsePhoneNumber(value, 'US')
                if (pn) {
                    return pn.isValid()
                } else {
                    return false
                }
            }
        }
    }
})

const routes = [
    { path: '/pm/request', component: PmRequestForm },
    { path: '/pm/request/submitted', component: PmRequestFollowup },
    { path: '/customer-exp', component: NewCustomerExperienceStart },
    { path: '/customer-exp/survey', component: NewCustomerExperience },
    { path: '/customer-exp/submitted', component: NewCustomerExperienceSubmitted },
    {
        path: '/just-fix-it',
        component: JustFixItPage,
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
})


new Vue({
    template: "<transition enter-active-class='transition-all transition-fastest ease-out-quad' leave-active-class='transition-all transition-faster ease-in-quad' enter-class='opacity-0 scale-70' enter-to-class='opacity-100 scale-100' leave-class='opacity-100 scale-100' leave-to-class='opacity-0 scale-70'><router-view/></transition>",
    router,
}).$mount('#app')