<template>
  <div id="new-customer-experience">
    <div class="max-w-lg mx-auto rounded-t bg-white p-4">
      <img src="/mra_send_coupon_450px.png" class="mx-auto" alt="Mr Appliance Logo">

      <h1 class="text-xl text-center max-w-md mx-auto my-6">
        Thank you for inviting Mr. Appliance of Orlando into your home today. It is our pleasure to serve you.
      </h1>

      <p class="text-md text-center max-w-md mx-auto mt-4 mb-2">
        Watch the video below to learn more about how we work and how to turn your visit today into an opportunity to change the lives of kids in Orlando.
      </p>

      <!--It is our privilege to introduce you to ELEVATE Orlando.-->
      <div class="w-10/12 mx-auto mb-4">
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe src="https://player.vimeo.com/video/571275688?color=ff9933&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay;"></iframe>
        </div>
      </div>

      <div class="continue-button cursor-pointer w-3/4 px-4 py-2 text-center rounded bg-orange-500 text-white hover:bg-orange-600 mx-auto"
        @click="continueFn">
        Get Your Coupon
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'NewCustomerExperienceStart',
  data () {
    return {

    }
  },
  components: {
  },
  methods: {
    continueFn () {
      this.$router.push('/customer-exp/survey' + (this.$route.query.source ? '?source=' + this.$route.query.source : ''))
    }
  }
}
</script>

<style>

</style>
