<template>
  <div id="followup" class="max-w-xl mx-auto p-4 bg-white rounded">
    <div class="max-w-lg mx-auto rounded-t bg-white pb-4 pl-4 pr-4">
      <img src="https://drive.google.com/uc?export=view&id=1pKmCDeScx3apmvdeGVFrJeGxAGOFkOUi" class="mx-auto" alt="Mr Appliance Logo" width="300px">

      <h1 class="text-2xl text-center max-w-md mx-auto pt-2">Mr. Appliance of Orlando Work Order Request Submitted!</h1>
    </div>

    <h3 class="text-xl m-8">Your work order request is popping up on our screens right now! We'll have it all taken care of in a giffy.</h3>

    <ul class="list-disc list-inside text-left pl-2 pr-2 pt-2 text-lg">
      <li>In the next few minutes: We call your tenant to schedule services.</li>
      <li>Our branded service professional will arrive at your tenant's home within the 2-hour window we communicated with them.</li>
      <li>Our service professional will diagnose the appliance and provide a quote which will be emailed to you.</li>
      <li>If you have automatic authorization amounts set for the appliance in question then we will fix the appliance if it is less than the threshold. If it is more than the threshold than we will contact you to receive authorization.</li>
      <li>If authorized, we will fix the appliance. If not, you will be charged the diagnostic fee.</li>
    </ul>

    <div class="contact-header mx-auto text-center text-lg mt-8">
      Dedicated Property Manager Contact Methods
    </div>
    <div class="contact-info flex mx-auto max-w-lg flex-auto">
      <div class="phone-number p-4 flex-grow text-center">
        <a href="tel:4077207979">407-720-7979</a>
      </div>
      <div class="email p-4 flex-grow text-center">
        <a href="mailto:pm@mraorlando.com">pm@mraorlando.com</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PmRequestFollowup',
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
  }
}
</script>

<style>
</style>
