<template>
  <div id="new-customer-experience">
    <div class="max-w-lg mx-auto rounded-t bg-white p-4">
      <img src="/mra_send_coupon_450px.png" class="w-full" alt="Mr Appliance Logo">

      <!-- <h1 class="text-2xl text-center max-w-md mx-auto pt-4 font-semibold">Survey</h1> -->
    </div>
     <FormulateForm
      @submit="submitForm"
      ref="form"
      class="max-w-lg mx-auto"
      v-model="values"
      :schema="newCustomerSchema"
    />
  </div>
</template>

<script>

// How long has it been since your dryer vent was cleaned?
// How long has it been since your dishwasher drain filter was cleaned?
// How long has it been since your fridge's coils were cleaned?
// Do you use dryer sheets?
// How does your garbage disposal sound?
// About how long have you lived here?
// While our service professional is in your home, think of any questions you may have about one of your appliances.

// Items to purchase...
// Refrigerator coil cleaning
// Dryer vent cleaning
// Garbage Disposal Change

const newCustomerSchema = [
  {
    component: 'div',
    class: 'p-4 bg-white',
    children: [
      // {
      //   component: 'h3',
      //   class: 'text-lg font-bold mb-4',
      //   children: 'Survey Questions'
      // },
      {
        name: 'how_long_dryer_vent',
        label: 'How long has it been since your dryer vent was cleaned?',
        type: 'radio',
        wrapper: 'flex items-center',
        help: 'A leading cause of house fires.',
        options: [
          "I don't remember it being done",
          "Less than 1 year",
          "1 to 2 years",
          "At least 2 years",
        ],
        "validation-name": 'Question',
        validation: 'required',
      },
      {
        name: 'how_long_dishwasher_drain',
        label: 'How long has it been since your dishwasher filter was cleaned?',
        type: 'radio',
        options: [
          "I don't remember it being done",
          "Less than 1 year",
          "1 to 2 years",
          "At least 2 years",
        ],
        "validation-name": 'Question',
        validation: 'required',
      },
      {
        name: 'how_long_coil_cleaning',
        label: 'How long has it been since your fridge\'s coils were cleaned?',
        type: 'radio',
        options: [
          "I don't remember it being done",
          "Less than 1 year",
          "1 to 2 years",
          "At least 2 years",
        ],
        "validation-name": 'Question',
        validation: 'required',
      },
      {
        name: 'how_does_garbage_disposal_sound',
        label: 'How does your garbage disposal sound?',
        type: 'radio',
        options: [
          "Sounds normal",
          "Terrible grinding metal",
        ],
        "validation-name": 'Question',
        validation: 'required',
      },
      {
        name: 'has_standalone_ice_machine',
        label: 'Do you have a standalone or under-counter ice machine?',
        type: 'radio',
        options: [
          "Yes",
          "No",
        ],
        "validation-name": 'Question',
        validation: 'required',
      },
      {
        name: 'how_long_dishwasher_fill_hose',
        label: 'How long has it been since your dishwasher fill hose was changed?',
        type: 'radio',
        options: [
          "Less than 5 years",
          "More than 5 years",
          "I don't know",
        ],
      },
      {
        name: 'how_long_washer_fill_hose',
        label: 'How long has it been since your washing machine fill hose was changed?',
        type: 'radio',
        options: [
          "Less than 5 years",
          "More than 5 years",
          "I don't know",
        ],
      },
      {
        name: 'how_long_live_here',
        label: 'About how long have you lived here?',
        type: 'radio',
        options: [
          "We just moved in!",
          "In the last year",
          "More than a year",
        ],
      },
    ]
  },

  {
    component: 'div',
    class: 'pl-4 pr-4 bg-white -mb-8',
    children: [
      {
        component: 'h3',
        class: 'text-2xl font-semibold mb-4 text-center',
        children: 'Other Service Offerings'
      },
      {
        name: 'service_diagnose_second_appliance',
        label: 'Diagnose another appliance - $59',
        type: 'checkbox',
        help: 'Are there any other appliances we should look at while we\'re here?'
      },
      {
        name: 'three_appliance_maint',
        label: 'Three Appliance Maintenance - $249',
        type: 'checkbox',
        help: 'Check out and maintain three of your major household appliances.',
      },
      // {
      //   name: 'service_coil_cleaning',
      //   label: 'Standard Refrigerator Coil Cleaning - $40',
      //   type: 'checkbox',
      //   help: 'Help your fridge cool more efficiently, save $ on power, and increase fridge longevity.'
      // },

      {
        name: 'service_coil_cleaning',
        label: 'Refrigerator Coil Cleaning- $40',
        type: 'checkbox',
        help: 'Help your fridge cool more efficiently, save $ on power, and increase fridge longevity.'
      },
      {
        name: 'two_appliance_maint',
        label: 'Two Appliance Maintenance - $195',
        type: 'checkbox',
        help: 'Check out and maintain two of your major household appliances.'
      },
      {
        name: 'one_appliance_maint',
        label: 'One Appliance Maintenance - $99',
        type: 'checkbox',
        help: 'Check out and maintain one of your major household appliances.'
      },
      // {
      //   name: 'service_dishwasher_filter_cleaning',
      //   label: 'Dishwasher Filter Cleaning - $40',
      //   type: 'checkbox',
      //   help: 'Help your dishwasher clean more effectively and increase longevity.'
      // },
      // {
      //   name: 'service_dryer_vent_cleaning',
      //   label: 'Dryer Vent Cleaning',
      //   help: 'Your service professional will give you a quote based on your vent.',
      //   type: 'checkbox',
      // },
      {
        name: 'service_disposal_change',
        label: 'Change your garbage disposal',
        type: 'checkbox',
        help: 'Your service professional will give you a quote based on your kitchen.'
      },
    ]
  },
  {
    component: 'div',
    class: 'pt-4 pl-4 pr-4 pb-4 bg-white rounded-b',
    children: [
      // {
      //   component: 'h3',
      //   class: 'font-bold text-lg',
      //   children: "One more thing to do for your coupon...",
      // },
      {
        component: 'div',
        class: 'pt-4',
        children: [
          {
            "type": "submit",
            "label": "Next",
            class: 'w-full'
          }
        ]
      }
    ]
  },
]

import axios from 'axios'


export default {
  name: 'NewCustomerExperience',
  data () {
    return {
      newCustomerSchema: newCustomerSchema,
      values: {},
      submissionStatus: 'new',
    }
  },
  components: {
  },
  methods: {
    submitForm (data) {
      console.log('here', data)
      if (this.submissionStatus === 'new') {
        this.submissionStatus = 'in-progress'
        const baseUrl = 'https://productivity-tools.webbintegrated.com'
        // const baseUrl = 'https://b4e0080cc8ae.ngrok.io'
        axios.post(`${baseUrl}/webhooks/mra_forms`, {
          form_name: 'new-customer-experience',
          source: this.$route.query.source,
          data: {
            ...this.values
          }
        })
        .then(resp => {
          console.log('response', resp)
          this.submissionStatus = 'sent'
          this.$router.push('/customer-exp/submitted' + (this.$route.query.source ? '?source=' + this.$route.query.source : ''))
        }, resp => {
          console.log('response', resp)
          this.submissionStatus = 'failed'
        })
      }

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
